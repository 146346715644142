import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../api"; // Votre instance API personnalisée
import { useCart } from "../cartContext/cartContext";
import { useAuth } from "../Context/authContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DetailsOwner = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { addToCart } = useCart();
  const { user, role } = useAuth();

  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [showDelivery, setShowDelivery] = useState(false);
  
  // Pour l'édition du produit (sans catégories)
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({
    nom: "",
    prix: "",
    description: "",
    stock: ""
  });

  // Récupération du produit
  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await api.get(`/produits/${id}`);
        setProduct(response.data);
        setEditData({
          nom: response.data.nom,
          prix: response.data.prix,
          description: response.data.description,
          stock: response.data.stock
        });
        setLoading(false);
      } catch (err) {
        setError("Erreur lors du chargement du produit");
        setLoading(false);
      }
    };
    fetchProductDetails();
  }, [id]);

  const handleDelete = async () => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce produit ?")) {
      try {
        await api.delete(`/produits/${id}`);
        navigate("/");
      } catch (err) {
        console.error("Erreur lors de la suppression du produit :", err);
        alert("Erreur lors de la suppression du produit");
      }
    }
  };

  const handleAddToCart = (product) => {
    addToCart(product);
    toast.success("Produit ajouté au panier !", {
      position: "top-right",
      autoClose: 3000,
      theme: "light",
    });
  };

  // Gestion du changement dans le formulaire d'édition
  const handleInputChange = (e) => {
    setEditData({
      ...editData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateProduit = async (e) => {
    e.preventDefault();
    try {
      await api.put(`/produits/${id}`, editData);
      toast.success("Produit modifié avec succès !");
      setEditMode(false);
      window.location.reload();
    } catch (err) {
      console.error("Erreur lors de la mise à jour du produit :", err);
      toast.error("Erreur lors de la mise à jour du produit");
    }
  };

  if (loading) return <div className="text-center text-lg py-6">Chargement...</div>;
  if (error) return <div className="text-center text-red-600 py-6">{error}</div>;
  if (!product) return <div className="text-center text-gray-500 py-6">Produit introuvable</div>;

  return (
    <div className="max-w-7xl mx-auto pl-6 pt-12 pr-0 pb-0 relative">
      <div className="bg-white rounded-lg shadow-lg p-8 flex flex-col md:flex-row gap-8 items-start min-h-[650px]">
        {/* IMAGE DU PRODUIT */}
        <div className="md:w-1/2 w-full flex justify-center">
          <img
            src={`https://backendecom-c518a29965ec.herokuapp.com/${product.chemin_image}`}
            alt={product.nom}
            className="w-full h-auto object-cover rounded-lg shadow-md"
          />
        </div>

        {/* INFOS PRODUIT */}
        <div className="md:w-1/2 w-full flex flex-col">
          <h1 className="text-3xl font-semibold text-gray-900 mb-2">{product.nom}</h1>
          <div className="border-b border-gray-300 mb-4"></div>
          <p className="text-2xl font-semibold text-green-600 mb-4">{product.prix}€</p>
          <div className="border-b border-gray-300 mb-4"></div>
          <p className="text-lg text-gray-700 mb-6">{product.description}</p>
          <div className="border-b border-gray-300 mb-4"></div>
          <div className="text-gray-500 text-sm mb-4">
            <p><span className="font-semibold">Stock :</span> {product.stock} unités</p>
            <p><span className="font-semibold">Date d'ajout :</span> {new Date(product.date_creation).toLocaleDateString()}</p>
            <p><span className="font-semibold">Référence :</span> {product.id}</p>
          </div>
          {product.SousCategories && product.SousCategories.length > 0 && (
            <div className="text-gray-500 text-sm mb-6">
              <span className="font-semibold">Sous-catégories :</span>
              <ul className="list-disc ml-4">
                {product.SousCategories.map((sousCat) => (
                  <li key={sousCat.id}>
                    {sousCat.nom}
                    {sousCat.Categorie && <span> (Catégorie : {sousCat.Categorie.nom})</span>}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {/* SECTION DÉROULANTE DÉTAIL */}
          <div className="mb-4">
            <button onClick={() => setShowDetails(!showDetails)} className="text-blue-600 font-semibold">
              Détails ▼
            </button>
            {showDetails && (
              <div className="mt-2 h-12 overflow-y-auto">
                <p className="text-gray-500">Nos articles sont nettoyés par des professionnels.</p>
              </div>
            )}
          </div>
          {/* SECTION DÉROULANTE LIVRAISON */}
          <div className="mb-6">
            <button onClick={() => setShowDelivery(!showDelivery)} className="text-blue-600 font-semibold">
              Livraison ▼
            </button>
            {showDelivery && (
              <div className="mt-2 h-12 overflow-y-auto">
                <p className="text-gray-500">Livraison sous 2 à 5 jours ouvrés.</p>
              </div>
            )}
          </div>
        </div>

        {/* BOUTONS (Responsive: absolute sur md, relative sur mobile) */}
        <div className="md:absolute relative md:bottom-4 md:right-4 flex gap-4">
          <button
            onClick={() => handleAddToCart(product)}
            className="px-5 py-2 text-white bg-blue-600 text-md rounded-md shadow-md hover:bg-blue-700 transition duration-200"
          >
            Ajouter au panier
          </button>
          {user && role === "admin" && (
            <>
              <button
                onClick={handleDelete}
                className="px-5 py-2 text-white bg-red-500 text-md rounded-md shadow-md hover:bg-red-600 transition duration-200"
              >
                Supprimer
              </button>
              <button
                onClick={() => setEditMode(true)}
                className="px-5 py-2 text-white bg-green-600 text-md rounded-md shadow-md hover:bg-green-700 transition duration-200"
              >
                Modifier
              </button>
            </>
          )}
        </div>
      </div>

      {/* Modal d'édition pour l'admin (sans catégories) */}
      {editMode && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-2xl overflow-auto max-h-[80vh]">
            <h2 className="text-2xl font-semibold mb-4">Modifier le produit</h2>
            <form onSubmit={handleUpdateProduit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-gray-700">Nom :</label>
                  <input
                    type="text"
                    name="nom"
                    value={editData.nom}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Prix :</label>
                  <input
                    type="number"
                    step="0.01"
                    name="prix"
                    value={editData.prix}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
              </div>
              <div className="mt-4">
                <label className="block text-gray-700">Description :</label>
                <textarea
                  name="description"
                  value={editData.description}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div className="mt-4">
                <label className="block text-gray-700">Stock :</label>
                <input
                  type="number"
                  name="stock"
                  value={editData.stock}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  type="button"
                  onClick={() => setEditMode(false)}
                  className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <ToastContainer position="top-right" autoClose={3000} style={{ zIndex: 9999 }} />
    </div>
  );
};

const DetailsOwnerWithToast = () => <DetailsOwner />;

export default DetailsOwnerWithToast;
