// api.js
import axios from 'axios';

// Création de l'instance Axios
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://backendecom-c518a29965ec.herokuapp.com',
  withCredentials: true, // si vous utilisez des cookies
});

// Récupération du token CSRF et configuration de l'en-tête
api.get('/api/csrf-token')
  .then(response => {
    api.defaults.headers.common['X-CSRF-Token'] = response.data.csrfToken;
  })
  .catch(error => {
    console.error("Erreur lors de la récupération du token CSRF :", error);
  });

// Intercepteur pour gérer automatiquement les erreurs 401 (token expiré)
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Ici, vous pouvez ajouter votre logique de déconnexion, par exemple :
      // - Supprimer le token stocké (dans localStorage ou cookies)
      // - Mettre à jour votre contexte d'authentification
      // - Rediriger vers la page de connexion
      console.warn("Token expiré ou non autorisé. Déconnexion automatique...");
      // Exemple simple de redirection :
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default api;
