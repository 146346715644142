import React, { createContext, useContext, useState, useEffect } from "react";
import {jwtDecode} from "jwt-decode";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

// Fonction pour vérifier si le token est expiré
const checkTokenExpiration = (token) => {
  try {
    const { exp } = jwtDecode(token);
    return exp * 1000 < Date.now();
  } catch (error) {
    return true;
  }
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const logout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("token");
    setUser(null);
    setRole(null);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      if (checkTokenExpiration(token)) {
        // Si le token est expiré, déconnecte automatiquement l'utilisateur
        logout();
        window.location.href = "/login"; // Redirige vers la page de connexion
      } else {
        try {
          const decoded = jwtDecode(token);
          const userId = decoded.userId || decoded.id;
          if (userId) {
            setUser({ id: userId, email: decoded.email, role: decoded.role });
            setRole(decoded.role || "user");
          }
        } catch (error) {
          console.error("Token invalide ou expiré", error);
          logout();
        }
      }
    }
    setIsLoading(false);
  }, []);

  const login = (userData, token) => {
    localStorage.setItem("userData", JSON.stringify(userData));
    localStorage.setItem("token", token);
    try {
      const decoded = jwtDecode(token);
      const userId = decoded.userId || decoded.id;
      setUser({ id: userId, email: decoded.email, role: decoded.role });
      setRole(decoded.role || "user");
    } catch (error) {
      console.error("Erreur lors du décodage du token", error);
      setRole("user");
    }
  };

  return (
    <AuthContext.Provider value={{ user, role, login, logout, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};
