import React from 'react';
import { Helmet } from "react-helmet";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-gray-100 py-16">
      <Helmet>
        <title>Contactez-nous - Ô Bonnes Affaires</title>
        <meta name="description" content="Vous avez des questions ou besoin d'aide ? Contactez-nous sur Ô Bonnes Affaires. Nous sommes là pour vous aider à trouver les meilleures affaires." />
        <meta property="og:title" content="Contactez-nous - Ô Bonnes Affaires" />
        <meta property="og:description" content="Notre équipe est disponible pour répondre à toutes vos questions et vous aider. Contactez-nous maintenant !" />
        <meta property="og:image" content="https://www.obonnesaffaires.com/images/contact-banner.jpg" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className="container mx-auto px-6">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-800">Contactez-nous</h1>
          <p className="text-lg text-gray-600 mt-2">Nous sommes là pour vous aider !</p>
        </div>

        <div className="flex flex-col md:flex-row justify-between gap-8">
          {/* Informations de contact */}
          <div className="w-full md:w-1/2 bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">Informations de contact</h2>

            <div className="flex items-center mb-6">
              <FaPhone size={30} className="text-green-500" />
              <div className="ml-4">
                <h3 className="font-semibold text-gray-700">Téléphone</h3>
                <p className="text-gray-600">07.58.06.47.54</p>
              </div>
            </div>

            <div className="flex items-center mb-6">
              <FaEnvelope size={30} className="text-blue-500" />
              <div className="ml-4">
                <h3 className="font-semibold text-gray-700">Email</h3>
                <p className="text-gray-600">contact@obonnesaffaires.fr</p>
              </div>
            </div>

            <div className="flex items-center mb-6">
              <FaMapMarkerAlt size={30} className="text-red-500" />
              <div className="ml-4">
                <h3 className="font-semibold text-gray-700">Adresse</h3>
                <p className="text-gray-600">31 avenue de la cour de France, 91260, Juvisy-sur-Orge</p>
              </div>
            </div>
          </div>

          {/* Map section avec la nouvelle carte */}
          <div className="w-full md:w-1/2 bg-white p-8 rounded-lg shadow-lg flex justify-center">
            <iframe
              title="Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2633.6960119631617!2d2.3678316769542747!3d48.6921763713102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e5df5ed5d6be0b%3A0xdc6bdcaefe9974ec!2s31%20Av.%20de%20la%20Cr%20de%20France%2C%2091260%20Juvisy-sur-Orge!5e0!3m2!1sfr!2sfr!4v1741463078739!5m2!1sfr!2sfr"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        {/* Section Qui sommes-nous ? */}
        <div className="mt-16 bg-white p-8 rounded-lg shadow-lg text-center">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">Qui sommes-nous ?</h2>
          <p className="text-lg text-gray-700">
            Découvrez l'histoire et la mission de Ô Bonnes Affaires ! Nous sommes dédiés à la mise en valeur des vêtements et objets de seconde main pour une mode plus durable et responsable.
          </p>
          <button
            onClick={() => navigate("/qui-sommes-nous")}
            className="mt-6 px-6 py-3 bg-blue-600 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-blue-700 transition"
          >
            En savoir plus
          </button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
